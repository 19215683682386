<template>
    <router-view />
</template>

<script>
    export default {
        name: 'App',
        mounted: function () {
            window.localStorage.setItem('env', process.env.VUE_APP_CURRENTMODE);
        }
    }
</script>
<style>
    html,
    body,
    #app {
        height: 100%;
        margin: 0;
        overflow: hidden;
        font-family: Microsoft YaHei !important;
        /* font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif; */
    }

    .el-tree {
        background: transparent !important;
    }
    
</style>