import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// elemetn-plus
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
// 清除默认样式
import "./styles/reset.scss";
// 字体: 思源、平方
import "./assets/fonts/font.css";
// pc适配
import "amfe-flexible";
const app = createApp(App);
//installElementPlus(app)
app
  .use(router)
  .use(ElementPlus)
  .mount("#app");
