import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: "/", name: "login", component: () => import("@/views/Login.vue") },
  {
    path: "/callback",
    name: "Callback",
    component: () => import("@/views/Callback.vue"),
  },
  {
    path: "/redirect/:appkey",
    name: "redirect",
    component: () => import("@/views/Redirect.vue"),
  },
  {
    path: "/pages",
    name: "Pages",
    component: () => import("../views/Pages.vue"),
    children: [
      {
        path: "info",
        name: "info",
        component: () => import("@/views/Info.vue"),
      },
      {
        path: "myapp",
        name: "Myapp",
        component: () => import("@/views/MyApp.vue"),
      },
      {
        path: "mg/:appkey",
        name: "Mg",
        component: () => import("@/views/Mg.vue"),
        children: [
          {
            path: "owner",
            name: "owner",
            component: () => import("@/components/role/Owner.vue"),
          },
          {
            path: "roles",
            name: "role",
            component: () => import("@/components/role/Role.vue"),
          },
          {
            path: "resource",
            name: "resource",
            component: () => import("@/components/role/Resource.vue"),
          },
          {
            path: "role/:roleId",
            name: "RoleDetail",
            component: () => import("@/components/role/RoleDetail.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: "/admin/apps",
    component: () => import("@/views/Admin/index.vue"),
    meta: { title: "首页" },
    children: [
      {
        path: "abovers",
        name: "Abover",
        // component: () => import("@/components/admin/Abovers.vue"),
        component: () => import("@/views/Admin/Abovers"),
        meta: { title: "超级管理员",icon:"el-icon-user"},
      },
      {
        path: "org",
        name: "Org",
        // component: () => import("@/components/admin/Org.vue"),
        component: () => import("@/views/Admin/Org"),
        meta: { title: "组织架构",icon:"el-icon-office-building" },
      },
      {
        path: "event",
        name: "Event",
        // component: () => import("@/components/admin/Event.vue"),
        component: () => import("@/views/Admin/Event"),
        meta: { title: "通讯录事件",icon:"el-icon-tickets" },
      },
      {
        path: "configure",
        name: "Configure",
        // component: () => import("@/components/admin/Event.vue"),
        component: () => import("@/views/Admin/Configure"),
        meta: { title: "相关配置",icon:"el-icon-set-up" },
      },
      {
        path: "/admin/apps",
        name: "App",
        // component: () => import("@/components/admin/Apps.vue"),
        component: () => import("@/views/Admin/Apps/layout.vue"),
        meta: { title: "应用管理",icon:"el-icon-menu"},
        redirect: "/admin/apps/list",
        children: [
          {
            path: "list",
            name: "App",
            meta: { title: "应用列表",icon:"el-icon-document-copy",hidden:false },
            component: () => import("@/views/Admin/Apps"),
          },
          {
            path: "add",
            name: "appAdd",
            meta: { title: "新增应用",icon:"el-icon-menu",hidden:true  },
            component: () => import("@/views/Admin/Apps/appAdd.vue"),
          },
          {
            path: "edit/:appkey",
            name: "appEdit",
            meta: { title: "编辑应用",icon:"el-icon-menu",hidden:true },
            component: () => import("@/views/Admin/Apps/appAdd.vue"),
          },
        ],
      },
      // {
      //   path: "apps/add",
      //   name: "appAdd",
      //   meta: { title: "新增应用" },
      //   component: () => import("@/views/Admin/Apps/appAdd.vue"),
      // },
      // {
      //   path: "apps/edit",
      //   name: "appEdit",
      //   meta: { title: "编辑应用" },
      //   component: () => import("@/views/Admin/Apps/appEdit.vue"),
      // },
      
    ],
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home/Index.vue"),
    redirect: "/home/application",
    children: [
      {
        path: "main",
        name: "Main",
        component: () => import("@/views/Home/Main.vue"),
      },
      {
        path: "application",
        name: "Application",
        component: () => import("@/views/Home/Application.vue"),
      },
      {
        path: "system",
        name: "System",
        component: () => import("@/views/Home/System.vue"),
      },
    ],
  },
  //{path: '/404', name: '404',component: () => import('@/views/404.vue')},
  //{ path: '/:pathMatch(.*)*',name: 'all', redirect: '/404'},
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
